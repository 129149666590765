import React from 'react'
import '../css/Skills.css'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

function Skills() {
  const slides = [
    {icon: process.env.PUBLIC_URL + "/assets/images/skills/csharp.svg", title: 'C#'},
    {icon: process.env.PUBLIC_URL + "/assets/images/skills/react.svg", title: 'React JS'},
    {icon: process.env.PUBLIC_URL + "/assets/images/skills/html.svg", title: 'HTML 5'},
    {icon: process.env.PUBLIC_URL + "/assets/images/skills/css.svg", title: 'CSS 3'},
    {icon: process.env.PUBLIC_URL + "/assets/images/skills/js.svg", title: 'JavaScript'},
    {icon: process.env.PUBLIC_URL + "/assets/images/skills/vsc.svg", title: 'VS Code'},
    {icon: process.env.PUBLIC_URL + "/assets/images/skills/github.svg", title: 'GitHub'},
  ]

  const slideLeft = () => {
    var slider = document.getElementById('slider')
    slider.scrollLeft = slider.scrollLeft - 323
  }

  const slideRight = () => {
    var slider = document.getElementById('slider')
    slider.scrollLeft = slider.scrollLeft + 323
  }

  return (
    <div className='skills'>
        <div className='content'>
          <div className='title'>
            <h1> SKILLS </h1>
          </div>
          <div className='info'>
            <MdChevronLeft size={40} className='arrow-left' onClick={slideLeft}/>
            <div className='slider' id='slider'>
              {
                slides.map((slide, index) => {
                  return(
                      <div className='card' key={index}>
                        <div className='icon'> <img src={slide.icon} alt='icon'/> </div>
                        <div className='text'> {slide.title} </div>
                      </div>
                  )
                })
              }
            </div>
            <MdChevronRight size={40} className='arrow-right' onClick={slideRight}/>
          </div>
        </div>
    </div>
  )
}

export default Skills
