import React, { useState } from 'react'
import { Link } from 'react-scroll'
import Typewriter from 'typewriter-effect'
import '../css/Home.css'

function Home() {
  const [arrowAnimation, setArrowAnimation] = useState(false)

  const arrowAnimationStart = () => setArrowAnimation(true)
  const arrowAnimationStop = () => setArrowAnimation(false)

  return (
    <div className='home'>
      <div className='content'>
        <div className='title'>
          <h1> PORTFOLIO </h1>
        </div>
        <div className='sub-title'>
          <h2>
            <Typewriter options={{strings: ['Designed by bagaco'], autoStart: true,
              cursor: ' />', loop: true}} />
          </h2>
        </div>
        <Link className='arrow-animation' to='about'
          onMouseOver={arrowAnimationStart} onMouseLeave={arrowAnimationStop}
          smooth={true} offset={-100} duration={500}>
          <i className={`fas fa-arrow-down ${arrowAnimation ? 'up-down-animation' : ''}`}  />
        </Link>
      </div>
    </div>
  );
}

export default Home;
