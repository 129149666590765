import React from 'react'
import '../css/Contact.css';
import emailjs from '@emailjs/browser';

function Contact() {
  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, 'form', process.env.REACT_APP_PUBLIC_KEY)
      .then(
        (result) => {
          console.log(result.text)
        },
          (error) => {
            console.log(error.text)
        }
      )
    }

    const contacts = [
      {icon: process.env.PUBLIC_URL + "/assets/images/socials/discord.svg", text: 'bagaco'},
      {icon: process.env.PUBLIC_URL + "/assets/images/socials/github.svg", text: 'bagacopt'},
      {icon: process.env.PUBLIC_URL + "/assets/images/socials/linkedin.svg", text: 'tomas-gouveia2005'}
    ]

  return (
    <div className='contact'>
      <div className='content'>
        <div className='title'>
          <h1> CONTACT ME! </h1>
        </div>

        <div className='info'>
          <div className='form'>
            <form onSubmit={sendEmail}>
              <div className='form-text'>
                <input type='text' placeholder='Name' name='name' required/>
                <input type='email' placeholder='Email' name='email' required/>
              </div>
              <input className='form-subject' type='text' placeholder='Subject' name='subject' required/>
              <textarea className='form-message' placeholder='Message' name='message' required/>
              <button className='form-button' type='submit'> Send Message </button>
            </form>
          </div>

          <div className='contacts'>
            {
              contacts.map((contact, index) => {
                return (
                  <div className='social' key={index}>
                    <img src={contact.icon} className='icon' alt='icon'/>
                    <p className='text'> {contact.text} </p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact