import React from 'react'
import '../css/Projects.css'

function Projects() {
  const projects = [
    {image: process.env.PUBLIC_URL + "/assets/images/projects/banners/PM-HOTELS.webp"},
    {image: process.env.PUBLIC_URL + "/assets/images/projects/banners/COMING-SOON.webp"},
    {image: process.env.PUBLIC_URL + "/assets/images/projects/banners/COMING-SOON.webp"},
    {image: process.env.PUBLIC_URL + "/assets/images/projects/banners/COMING-SOON.webp"},
    {image: process.env.PUBLIC_URL + "/assets/images/projects/banners/COMING-SOON.webp"},
    {image: process.env.PUBLIC_URL + "/assets/images/projects/banners/COMING-SOON.webp"},
  ]

  return (
    <div className='projects'>
      <div className='content'>
        <div className='title'>
          <h1> PROJECTS </h1>
        </div>

        <div className='info'>
          <div className='cards'>
            {
              projects.map((project, index) => {
                return (
                  <div className='card' key={index}>
                    <div className='img'> <img src={project.image} alt='project-banner'/> </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Projects
