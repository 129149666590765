import React, { useState } from 'react'
import { Link, Button } from 'react-scroll'
import { FaBars, FaTimes } from 'react-icons/fa'
import '../css/Navbar.css'

function Navbar() {
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)
  const closeMenu = () => setClick(false)

  const nav_items = [
    {to: 'about', text: 'ABOUT'},
    {to: 'skills', text: 'SKILLS'},
    {to: 'projects', text: 'PROJECTS'}
  ]

  return (
    <nav className='navbar'>
        <div className='content'>
            <Link className='title' to='home' smooth={true} offset={-100} duration={1000} onClick={closeMenu}> PORTFOLIO </Link>

            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                {
                    nav_items.map((item, index) => {
                        return (
                            <li className='nav-item' key={index}>
                                <Link className='nav-links' to={item.to}  smooth={true} offset={-100} duration={500} onClick={closeMenu}> {item.text} </Link>
                            </li>
                        )
                    })
                }
            </ul>

            <Link className='button'>
                <Button className='contact-btn' to='contact' smooth={true} offset={-100} duration={1000} onClick={closeMenu}> Contact Me </Button>
            </Link>

            <div className='menu-icon' onClick={handleClick}>
                {click ? (<FaTimes size={30} />) : (<FaBars size={30} />)}
            </div>
        </div>
    </nav>
  )
}

export default Navbar
