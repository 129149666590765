import './App.css';
import Navbar from './components/js/Navbar'
import Home from './components/js/Home'
import About from './components/js/About'
import Skills from './components/js/Skills'
import Projects from './components/js/Projects'
import Contact from './components/js/Contact'
import Footer from './components/js/Footer'

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <About />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </>
  );
}

export default App;