import React from 'react'
import '../css/About.css'

function About() {
  const links = [
    {href: 'https://github.com/bagacopt', alt: 'Github', src: 'https://img.shields.io/badge/GitHub-100000?style=for-the-badge&logo=github&logoColor=white'},
    {href: 'https://www.linkedin.com/in/tomas-gouveia2005/', alt: 'Linkedin', src: 'https://img.shields.io/badge/LinkedIn-0077B5?style=for-the-badge&logo=linkedin&logoColor=white'},
    {href: 'https://www.instagram.com/bagaco.02/', alt: 'Instagram', src: 'https://img.shields.io/badge/Instagram-E4405F?style=for-the-badge&logo=instagram&logoColor=white'},
    {href: 'https://twitter.com/bagaco_02', alt: 'Twitter', src: 'https://img.shields.io/badge/Twitter-1DA1F2?style=for-the-badge&logo=twitter&logoColor=white'},
    {href: 'https://steamcommunity.com/id/bagacopt', alt: 'Steam', src: 'https://img.shields.io/badge/Steam-000000?style=for-the-badge&logo=steam&logoColor=white'},
    {href: 'https://www.twitch.tv/bagacopt_', alt: 'Twitch', src: 'https://img.shields.io/badge/Twitch-%239146FF.svg?style=for-the-badge&logo=Twitch&logoColor=white'},
  ]

  return (
    <div className='about'>
      <div className='content'>
        <div className='title'>
          <h1> ABOUT ME! </h1>
        </div>

        <div className='info'>
          <div className='img'>
              <img src={process.env.PUBLIC_URL +'/assets/images/general/ABOUT-PFP.webp'}
                alt='profile-img' className='profile-img' />
          </div>
          <div className='text'>
            <p>
              My name is Tomás Gouveia, I'm from Portugal and I'm a 18 years old freelancer
              who achieves to gain more and more experience and knowledge about all kinds
              of programming languages and how we can use them on our daily basis to solve
              problems or other issues we might have with technology.

              <br/>
              <br/>

              I'm passionate about learning about technology and how it is changing the world
              we live in, and my biggest goal is to be able to make a difference in the
              world, for the better of everyone, for the better of the planet, and for the advance
              of technology.
            </p>
            <div className='links'>
              {
                links.map((link, index) => {
                  return (
                    <a href={link.href} target='_blank' rel='noreferrer' key={index}>
                      <img alt={link.alt} src={link.src} />
                    </a>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
